export const requireValue = <T>(o: T): T => {
    if (o === undefined || o === null) throw new Error('value required');
    return o;
}

export const requireNotBlank = (o: any) => {
    if (o === undefined || o === null || (typeof o === 'string' && o.trim() === '')) {
        throw Error('value must not be blank');
    }
    return o;
}